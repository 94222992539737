/*
 * @Author: wxb
 * @Date: 2022-04-12 11:42:26
 * @LastEditors: wxb
 * @LastEditTime: 2022-04-12 17:37:05
 * @Description:
 */
import request from '@/utils/request'

/**
 * @description: 获取明星企业列表
 * @param {*} params
 * @return {*}
 */
export function getStarEnterpriseList(params) {
  return request({
    url: '/api/company/famousEnterprise/getStarEnterpriseList',
    method: 'get',
    params
  })
}
/**
 * @description: 获取明星企业详情
 * @param {*} params
 * @return {*}
 */
export function getStarEnterpriseByOne(params) {
  return request({
    url: '/api/company/famousEnterprise/getStarEnterpriseByOne',
    method: 'get',
    params
  })
}
/**
 * @description: 查询推荐产品列表
 * @param {*} params
 * @return {*}
 */
export function getStarRecommendproductList(params) {
  return request({
    url: '/api/company/famousEnterprise/getStarRecommendproductList',
    method: 'get',
    params
  })
}
/**
 * @description: 查询解决方案列表
 * @param {*} params
 * @return {*}
 */
export function getStarSolutionList(params) {
  return request({
    url: '/api/company/famousEnterprise/getStarSolutionList',
    method: 'get',
    params
  })
}

/**
 * @description: 获取主营产品列表
 * @param {*} params
 * @return {*}
 */
export function getStarProductList(params) {
  return request({
    url: '/api/company/famousEnterprise/getStarProductList',
    method: 'get',
    params
  })
}

/**
 * @description: 获取产品详情
 * @param {*} params
 * @return {*}
 */
export function getProductByIdInfo(params) {
  return request({
    url: '/api/company/famousEnterprise/getProductByIdInfo',
    method: 'get',
    params
  })
}

/**
 * @description: 获取解决方案详情
 * @param {*} params
 * @return {*}
 */
export function getStarSolutionById(params) {
  return request({
    url: '/api/company/famousEnterprise/getStarSolutionById',
    method: 'get',
    params
  })
}
