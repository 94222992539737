<!--
 * @Author: wxb
 * @Date: 2022-03-23 09:19:10
 * @LastEditors: wxb
 * @LastEditTime: 2022-04-21 16:52:59
 * @Description:
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='9'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/starCompany' }">明星企业</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/starCompanyDetail/${companyDetail.enterpriseId}/${0}`}">{{companyDetail.enterpriseName}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{solutionDetail.programme}}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="company-item">
        <div class="company-item-logo">
          <img class="logo"
               :src="companyDetail.logo && companyDetail.logo.path ? `/api/company/fileManagementNoLogin/preview?fileName=${companyDetail.logo.name}&filePath=${companyDetail.logo.path}`: nologo" />
        </div>
        <div class="company-item-content">
          <div class="company-title">{{companyDetail.enterpriseName}}</div>
          <div class="company-contacts">
            <div class="contact-item">联系人：<span class="info">{{companyDetail.contacts}}</span></div>
            <div class="contact-item">联系方式：<span class="info">{{companyDetail.contactTelephone}}</span></div>
          </div>
          <div class="company-address">机构地址：<span class="info">{{companyDetail.diZh}}</span></div>
          <div class="company-address">主营：<span class="info">{{companyDetail.mainBusiness}}</span></div>
        </div>
      </div>
      <div class="company-nav">
        <div class="company-nav-item"
             v-for="(item,index) in navList"
             :key="index"
             :class="activeNav===index?'active':''"
             @click="handleClickNav(index)">{{item}}</div>
      </div>
      <div class="company-nav-content">
        <div class="pro-detail">
          <div class="pro-detail-left">
            <div class="pro-detail-left-header">
              <i class="el-icon-collection-tag"></i> 方案列表
            </div>
            <div class="pro-detail-left-content">
              <div class="recommend-list">
                <div class="recommend-list-item"
                     v-for="(item,index) in recommendList"
                     :key="index"
                     :title="item.programme"
                     @click="handleClickRecommend(item.id)">
                  <span class="recommend-dot"></span>
                  <span class="recommend-name">{{item.programme}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="pro-detail-right">
            <div class="solution">
              <div class="solution-header">{{solutionDetail.programme}}</div>
              <div class="solution-content"
                   v-html="solutionDetail.programContent"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { getStarEnterpriseByOne, getStarSolutionList, getStarSolutionById } from '@/api/startCompany/index'
import nologo from '@/assets/no-logo.png'
export default {
  name: 'starCompanyDetail',
  components: {
    headerNav,
    footerNav
  },
  data() {
    return {
      solutionId: '',
      companyId: '',
      companyDetail: {},
      navList: ['首页', '解决方案', '主营产品'],
      activeNav: 1,
      recommendList: [],
      solutionDetail: {},
      nologo
    }
  },
  methods: {
    handleClickNav(i) {
      this.$router.push({
        name: 'starCompanyDetail',
        params: { id: this.companyId, nav: i }
      })
    },
    handleClickRecommend(id) {
      const routeData = this.$router.resolve({
        name: 'solutionDetaill',
        params: { proid: id, comid: this.companyId }
      })
      window.open(routeData.href, '_blank')
    },
    loadCompanyInfo() {
      getStarEnterpriseByOne({ enterpriseId: this.companyId }).then(res => {
        if (res.ok) {
          this.companyDetail = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    loadRecommendSolution() {
      getStarSolutionList({ start: 0, limit: 20, enterpriseId: this.companyId }).then(res => {
        if (res.ok) {
          this.recommendList = res.lst
        }
      }).catch(err => {
        console.log(err)
      })
    },
    loadSolutionDetail() {
      getStarSolutionById({
        id: this.solutionId
      }).then(res => {
        if (res.ok) {
          if (res.data.programContent) {
            res.data.programContent = res.data.programContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&apos;/g, '\'')
          }
          this.solutionDetail = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    this.solutionId = this.$route.params.proid
    this.companyId = this.$route.params.comid
    this.loadCompanyInfo()
    this.loadRecommendSolution()
    this.loadSolutionDetail()
  }
}
</script>
<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}
// 企业基本信息
.company {
  &-item {
    position: relative;
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    &-content {
      flex: 1;
      padding-left: 10px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &-logo {
      width: 240px;
      background: #ffffff;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: auto;
        height: 88px;
      }
    }

    .info {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  &-title {
    box-sizing: border-box;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 32px;
    font-weight: 500;
  }

  &-contacts {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 40px;
    font-weight: 400;
    display: flex;
    align-items: center;
    .contact {
      &-item {
        width: 400px;
      }
    }
  }

  &-address {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 40px;
    font-weight: 400;
  }
}

// 导航信息
.company {
  &-nav {
    background: #fafafa;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &-item {
      padding: 15px 0;
      width: 200px;
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      font-weight: 400;
      cursor: pointer;

      &.active {
        color: #ff9b41;
        border-bottom: 2px solid #ff9b41;
      }
    }

    &-content {
      padding: 10px 0;
    }
  }
}

// 产品信息
.pro-detail {
  display: flex;
  margin-top: 10px;
  &-left {
    width: 220px;

    &-header {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 18px;
      font-weight: 500;
    }

    &-content {
      .recommend {
        &-list {
          box-sizing: border-box;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          padding-right: 10px;
          padding-top: 10px;
          &-item {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 45px;
            font-weight: 500;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
              background: #fff5ec;
              color: #ff9b41;
            }
          }
        }

        &-dot {
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #d8d8d8;
          margin-right: 10px;
          margin-left: 10px px;
        }

        &-name {
          display: inline-block;
          white-space: nowrap; /*超出的空白区域不换行*/
          overflow: hidden; /*超出隐藏*/
          text-overflow: ellipsis; /*文本超出显示省略号*/
        }
      }
    }
  }

  &-right {
    flex: 1;
    padding-left: 10px;

    .solution {
      margin-top: 20px;
      &-header {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 18px;
        font-weight: 500;
        padding-left: 10px;
        text-align: center;
      }

      &-content {
        margin-top: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 0;
        font-size: 16px;
        @import "@/style/wangeditor/style/index.scss";
      }
    }
  }
}
</style>
